"use client";

import * as RadixDialog from "@radix-ui/react-dialog";
import { forwardRef, ReactNode, useImperativeHandle, useRef } from "react";

import Icon from "./Icon";
import Typography from "./Typography";

export type ExtendedDialogProps = {
  fullSize?: boolean;
  showOverlay?: boolean;
  title: ReactNode;
  children: ReactNode;
  onClose?: () => void;
  trigger?: ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  link?: string;
  noClose?: boolean;
};

const ExtendedDialog = forwardRef<HTMLDivElement, ExtendedDialogProps>(
  (
    {
      fullSize = false,
      title,
      children,
      onClose,
      trigger = null,
      open,
      onOpenChange,
      link,
      noClose = false,
    },
    ref,
  ) => {
    const dialogRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => dialogRef.current!);

    const handleOpenChange = (newOpen: boolean) => {
      if (!newOpen) {
        onClose?.();
      }
      onOpenChange?.(newOpen);
    };

    return (
      <RadixDialog.Root open={open} onOpenChange={handleOpenChange}>
        {trigger && (
          <RadixDialog.Trigger asChild>{trigger}</RadixDialog.Trigger>
        )}

        <RadixDialog.Portal>
          <RadixDialog.Content
            id="dialog-content"
            ref={dialogRef}
            aria-describedby="description"
            className={`fixed z-[99] bg-white rounded-lg shadow-xl overflow-auto border border-canvas-400 ${
              fullSize
                ? "inset-0 m-0"
                : "top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 max-w-5xl max-h[90vh] h-full"
            }`}
          >
            <div className="h-[55px] bg-canvas-200 flex justify-end">
              <RadixDialog.Title className="mb-4 flex-1 self-center mt-4 ml-4">
                <Typography.Heading component="div" size="xs" alt>
                  {title}
                </Typography.Heading>
              </RadixDialog.Title>
              <div className="p-4 mr-2">
                {link && (
                  <a href={link ?? "#"} target="_blank" rel="noreferrer">
                    <Icon
                      src="/images/icon-external-link.svg"
                      width={24}
                      height={24}
                      alt="external link"
                    />
                  </a>
                )}
              </div>
              {!noClose && (
                <RadixDialog.Close className="pr-6 text-neutral-500 hover:text-neutral-700 cursor-pointer flex items-center">
                  <Typography.Heading
                    size="lg"
                    className="!font-besley"
                    alt
                    color="inherit"
                  >
                    &times;
                  </Typography.Heading>
                </RadixDialog.Close>
              )}
            </div>

            <RadixDialog.Description asChild>
              <div className="mb-4 h-full">{children}</div>
            </RadixDialog.Description>
          </RadixDialog.Content>
        </RadixDialog.Portal>
      </RadixDialog.Root>
    );
  },
);

ExtendedDialog.displayName = "ExtendedDialog";

export default ExtendedDialog;
