export const AppConfig = {
  localStorageCacheTimeoutMin: 5,
  queryStaleTimeMs: 1000 * 60 * 5, // 5 minutes
  queryCacheTimeMs: 1000 * 60 * 8, // 8 minutes
  useLocalStorage: process.env.CFT_USE_LOCAL_STORAGE === "true" || false,
  forestLocalStorageTimeMs: 1000 * 60 * 60 * 24 * 30, // 30 days
  pusherRefreshContributionsChannel: "public-new_contribution_$accountId",
};

export default AppConfig;
